<template>
  <div class="w-11/12 lg:mt-24 mx-auto min-h-screen pt-18 mb-24">
    <div id="timeline" style="width: 100%; height: 800px"></div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import timelineData from '@/static/timeline.json';

export default {
  async mounted() {
    await nextTick();

    // console.log(timeline);
    let spreadSheet =
      'https://docs.google.com/spreadsheets/d/1CqVREsnx2N0g4W2Rf8tl0QL5oJQFHJPnXRAptbNOK0s/edit#gid=0';

    let script = document.createElement('script');
    script.setAttribute('src', '/js/timeline.js');

    script.addEventListener('load', (e) => {
      setTimeout(() => {
        const timeline = new window.TL.Timeline('timeline', spreadSheet);

        timeline.on('ready', () => {
          let anchors = document.querySelectorAll('[data-anchor]');

          Array.prototype.forEach.call(anchors, function(a) {
            a.addEventListener('click', () => {
              let target = document.getElementById(
                a.dataset.anchor + '-marker',
              );

              if (target) {
                target.click();
              }
            });
          });
        });
      }, 1);
    });

    document.head.appendChild(script);
  },
};
</script>

<style lang="scss">
.tl-slide-content[style] {
  width: 100% !important;
}

.tl-layout-portrait .tl-storyslider[style] {
  height: 100% !important;
}

.tl-slider-background.tl-animate[style] {
  background-color: transparent !important;
}
</style>
